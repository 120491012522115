/**
 * Do not edit directly
 * Generated on Wed, 15 Jan 2025 13:31:05 GMT
 */

[data-brand="simpel"][data-theme="dark"], [data-brand="simpel"] [data-theme="dark"] {
  --type-h3-xl-line-height: 1.231;
  --type-h3-xl-font-size: 1.625rem;
  --type-h3-sm-line-height: 1.3;
  --type-h3-sm-font-size: 1.25rem;
  --type-h3-font-weight: 700;
  --type-h3-line-height: 1.3;
  --type-h3-font-size: 1.25rem;
  --type-h2-xl-line-height: 1.17;
  --type-h2-xl-font-size: 2.125rem;
  --type-h2-sm-line-height: 1.231;
  --type-h2-sm-font-size: 1.625rem;
  --type-h2-font-weight: 700;
  --type-h2-line-height: 1.25;
  --type-h2-font-size: 1.5rem;
  --type-h1-xl-line-height: 1.12;
  --type-h1-xl-font-size: 3.125rem;
  --type-h1-sm-line-height: 1.143;
  --type-h1-sm-font-size: 2.625rem;
  --type-h1-text-transform: none;
  --type-h1-font-weight: 700;
  --type-h1-line-height: 1.1765;
  --type-h1-font-size: 2.125rem;
  --type-font-family-display: "Arial", "freesans", sans-serif;
  --type-font-family: "Arial", "freesans", sans-serif;
  --type-tiny-line-height: 1.334;
  --type-tiny-font-size: 0.75rem;
  --type-small-line-height: 1.43;
  --type-small-font-size: 0.875rem;
  --type-line-height: 1.375;
  --type-font-size: 1rem;
  --type-h5-line-height: 1.3333;
  --type-h5-font-size: 1.125rem;
  --type-h4-xl-line-height: 1.27;
  --type-h4-xl-font-size: 1.375rem;
  --type-h4-line-height: 1.3;
  --type-h4-font-size: 1.25rem;
  --color-chart-2: #ea0012;
  --color-chart-1: #ff8787;
  --color-campaign-1-bg: #c2000f;
  --color-campaign-1-text: #ffdddd;
  --color-brand-expressive-bg-strong-pressed: #9a000c;
  --color-brand-expressive-bg-strong-hover: #c2000f;
  --color-brand-expressive-bg-strong: #ea0012;
  --color-brand-expressive-bg-subtle: #690008;
  --color-brand-expressive-border-subtle: #ea0012;
  --color-brand-expressive-border: #ff4b4b;
  --color-brand-expressive-graphic: #ff4b4b;
  --color-brand-expressive-text-hover: #ffb4b4;
  --color-brand-expressive-text: #ff8787;
  --color-brand-primary-bg-strong-pressed: #9a000c;
  --color-brand-primary-bg-strong-hover: #c2000f;
  --color-brand-primary-bg-strong: #ea0012;
  --color-brand-primary-bg-subtle: #460005;
  --color-brand-primary-border-subtle: #9a000c;
  --color-brand-primary-border: #ff4b4b;
  --color-brand-primary-graphic: #ff4b4b;
  --color-brand-primary-text-subtle: #ffdddd;
  --color-brand-primary-text-hover: #ffb4b4;
  --color-brand-primary-text: #ff8787;
  --color-palette-magenta-950: #320113;
  --color-palette-magenta-900: #4d0423;
  --color-palette-magenta-800: #680835;
  --color-palette-magenta-700: #861348;
  --color-palette-magenta-600: #ac165b;
  --color-palette-magenta-500: #dd0067;
  --color-palette-magenta-400: #f6529f;
  --color-palette-magenta-300: #f686bb;
  --color-palette-magenta-200: #f4afcf;
  --color-palette-magenta-100: #fbe1ed;
  --color-palette-magenta-50: #fef0f7;
  --color-palette-yellow-950: #181000;
  --color-palette-yellow-900: #291b00;
  --color-palette-yellow-800: #492f00;
  --color-palette-yellow-700: #644100;
  --color-palette-yellow-600: #7b5101;
  --color-palette-yellow-500: #865a07;
  --color-palette-yellow-400: #c69414;
  --color-palette-yellow-300: #dca415;
  --color-palette-yellow-200: #eeb00f;
  --color-palette-yellow-150: #fdd001;
  --color-palette-yellow-100: #ffeb90;
  --color-palette-yellow-50: #fff4c3;
  --color-palette-beige-950: #170f09;
  --color-palette-beige-900: #241c16;
  --color-palette-beige-800: #3b332d;
  --color-palette-beige-700: #4f4741;
  --color-palette-beige-600: #665e58;
  --color-palette-beige-500: #807268;
  --color-palette-beige-400: #a08a7a;
  --color-palette-beige-300: #bba494;
  --color-palette-beige-200: #d7c3b5;
  --color-palette-beige-100: #ede1d8;
  --color-palette-beige-50: #f9f1ec;
  --color-palette-black-950: rgba(0, 0, 0, 0.93);
  --color-palette-black-900: rgba(0, 0, 0, 0.88);
  --color-palette-black-850: rgba(0, 0, 0, 0.84);
  --color-palette-black-800: rgba(0, 0, 0, 0.8);
  --color-palette-black-700: rgba(0, 0, 0, 0.71);
  --color-palette-black-600: rgba(0, 0, 0, 0.62);
  --color-palette-black-500: rgba(0, 0, 0, 0.54);
  --color-palette-black-400: rgba(0, 0, 0, 0.44);
  --color-palette-black-300: rgba(0, 0, 0, 0.33);
  --color-palette-black-200: rgba(0, 0, 0, 0.22);
  --color-palette-black-150: rgba(0, 0, 0, 0.16);
  --color-palette-black-100: rgba(0, 0, 0, 0.1);
  --color-palette-black-50: rgba(0, 0, 0, 0.05);
  --color-palette-grey-1000: #000000;
  --color-palette-grey-950: #111111;
  --color-palette-grey-900: #1e1e20;
  --color-palette-grey-850: #252528;
  --color-palette-grey-800: #303033;
  --color-palette-grey-700: #4a4a4d;
  --color-palette-grey-600: #5f5f63;
  --color-palette-grey-500: #757579;
  --color-palette-grey-400: #8d8d94;
  --color-palette-grey-300: #aaaab0;
  --color-palette-grey-200: #c6c6cd;
  --color-palette-grey-150: #d6d6dd;
  --color-palette-grey-100: #e5e5eb;
  --color-palette-grey-50: #f3f3f8;
  --color-palette-grey-0: #ffffff;
  --color-palette-white-950: rgba(255, 255, 255, 0.95);
  --color-palette-white-900: rgba(255, 255, 255, 0.89);
  --color-palette-white-850: rgba(255, 255, 255, 0.84);
  --color-palette-white-800: rgba(255, 255, 255, 0.79);
  --color-palette-white-700: rgba(255, 255, 255, 0.66);
  --color-palette-white-600: rgba(255, 255, 255, 0.55);
  --color-palette-white-500: rgba(255, 255, 255, 0.46);
  --color-palette-white-400: rgba(255, 255, 255, 0.37);
  --color-palette-white-300: rgba(255, 255, 255, 0.29);
  --color-palette-white-200: rgba(255, 255, 255, 0.21);
  --color-palette-white-150: rgba(255, 255, 255, 0.16);
  --color-palette-white-100: rgba(255, 255, 255, 0.11);
  --color-palette-white-50: rgba(255, 255, 255, 0.07);
  --color-palette-pink-950: #20071c;
  --color-palette-pink-900: #370b30;
  --color-palette-pink-800: #670e55;
  --color-palette-pink-700: #891774;
  --color-palette-pink-600: #a9188d;
  --color-palette-pink-550: #b12399;
  --color-palette-pink-500: #c42ead;
  --color-palette-pink-400: #e558c9;
  --color-palette-pink-300: #f082d9;
  --color-palette-pink-200: #f5b3e8;
  --color-palette-pink-100: #f9daf5;
  --color-palette-pink-50: #fceffb;
  --color-palette-orange-950: #1a0f00;
  --color-palette-orange-900: #2b1b03;
  --color-palette-orange-800: #4c2f03;
  --color-palette-orange-700: #6a4102;
  --color-palette-orange-600: #8b5301;
  --color-palette-orange-500: #a86602;
  --color-palette-orange-400: #d17e00;
  --color-palette-orange-300: #f19812;
  --color-palette-orange-200: #f9bb5b;
  --color-palette-orange-100: #ffe1b3;
  --color-palette-orange-50: #fff2de;
  --color-palette-red-950: #2c000e;
  --color-palette-red-900: #440016;
  --color-palette-red-800: #700025;
  --color-palette-red-700: #980233;
  --color-palette-red-600: #c00543;
  --color-palette-red-500: #e4175c;
  --color-palette-red-400: #f15c8b;
  --color-palette-red-300: #ff80ab;
  --color-palette-red-200: #ffafc9;
  --color-palette-red-100: #ffdde8;
  --color-palette-red-50: #fff0f5;
  --color-palette-teal-950: #011413;
  --color-palette-teal-900: #032220;
  --color-palette-teal-800: #063c39;
  --color-palette-teal-700: #02534f;
  --color-palette-teal-600: #006c67;
  --color-palette-teal-500: #00847e;
  --color-palette-teal-400: #579e9a;
  --color-palette-teal-300: #6eb5b2;
  --color-palette-teal-200: #a1cfcd;
  --color-palette-teal-100: #d5eae9;
  --color-palette-teal-50: #edf5f5;
  --color-palette-blue-950: #011320;
  --color-palette-blue-900: #002036;
  --color-palette-blue-800: #03385b;
  --color-palette-blue-700: #014d80;
  --color-palette-blue-600: #0063a6;
  --color-palette-blue-500: #0078cb;
  --color-palette-blue-400: #3898d8;
  --color-palette-blue-300: #62b2e7;
  --color-palette-blue-200: #91cdf4;
  --color-palette-blue-100: #caeaff;
  --color-palette-blue-50: #e8f6ff;
  --color-palette-green-950: #00150a;
  --color-palette-green-900: #002411;
  --color-palette-green-800: #003e1e;
  --color-palette-green-700: #02562b;
  --color-palette-green-600: #017037;
  --color-palette-green-500: #018842;
  --color-palette-green-400: #21a762;
  --color-palette-green-300: #3dbf7c;
  --color-palette-green-200: #79d9a7;
  --color-palette-green-100: #c5efd9;
  --color-palette-green-50: #e1f9ec;
  --color-palette-purple-950: #1f002e;
  --color-palette-purple-900: #29003e;
  --color-palette-purple-800: #4e0174;
  --color-palette-purple-700: #6d02a3;
  --color-palette-purple-600: #8c07d0;
  --color-palette-purple-550: #990ae3;
  --color-palette-purple-500: #b12df4;
  --color-palette-purple-400: #c461f7;
  --color-palette-purple-300: #d58df9;
  --color-palette-purple-200: #e4b6fb;
  --color-palette-purple-100: #f5e0ff;
  --color-palette-purple-50: #faf0ff;
  --type-h3-font-family: var(--type-font-family);
  --type-h2-font-family: var(--type-font-family-display);
  --type-h1-font-family: var(--type-font-family-display);
  --color-chart-7: var(--color-palette-blue-200);
  --color-chart-6: var(--color-palette-pink-550);
  --color-chart-5: var(--color-palette-blue-400);
  --color-chart-4: var(--color-palette-orange-500);
  --color-chart-3: var(--color-palette-green-300);
  --color-campaign-4-bg: var(--color-palette-teal-500);
  --color-campaign-4-text: var(--color-palette-grey-0);
  --color-campaign-3-bg: var(--color-palette-red-500);
  --color-campaign-3-text: var(--color-palette-grey-0);
  --color-campaign-2-bg: var(--color-palette-red-800);
  --color-campaign-2-text: var(--color-palette-red-300);
  --color-brand-secondary-bg-subtle: var(--color-palette-beige-800);
  --color-brand-secondary-bg-subtlest-hover: var(--color-palette-beige-800);
  --color-brand-secondary-bg-subtlest: var(--color-palette-beige-900);
  --color-brand-secondary-text: var(--color-palette-beige-200);
  --color-brand-expressive-text-onbg-strong: var(--color-palette-grey-0);
  --color-brand-primary-text-onbg-strong: var(--color-palette-grey-0);
  --color-success-bg-strong-pressed: var(--color-palette-green-700);
  --color-success-bg-strong-hover: var(--color-palette-green-600);
  --color-success-bg-strong: var(--color-palette-green-500);
  --color-success-bg-subtle: var(--color-palette-green-800);
  --color-success-border-subtle: var(--color-palette-green-700);
  --color-success-border: var(--color-palette-green-400);
  --color-success-graphic: var(--color-palette-green-400);
  --color-success-text-onbg-strong: var(--color-palette-grey-0);
  --color-success-text: var(--color-palette-green-300);
  --color-neutral-bg-level-4: var(--color-palette-grey-800);
  --color-neutral-bg-level-3: var(--color-palette-grey-850);
  --color-neutral-bg-level-2: var(--color-palette-grey-900);
  --color-neutral-bg-level-1: var(--color-palette-grey-950);
  --color-neutral-bg-strong-pressed: var(--color-palette-grey-300);
  --color-neutral-bg-strong-hover: var(--color-palette-grey-100);
  --color-neutral-bg-strong: var(--color-palette-grey-200);
  --color-neutral-bg-hover: var(--color-palette-white-50);
  --color-neutral-bg-subtle: var(--color-palette-white-100);
  --color-neutral-bg-subtlest: var(--color-palette-white-50);
  --color-neutral-border-subtlest: var(--color-palette-white-100);
  --color-neutral-border-subtle: var(--color-palette-white-200);
  --color-neutral-border: var(--color-palette-white-500);
  --color-neutral-graphic: var(--color-palette-grey-0);
  --color-neutral-text-onbg-strong: var(--color-palette-grey-1000);
  --color-neutral-text-subtle: var(--color-palette-white-700);
  --color-neutral-text: var(--color-palette-white-900);
  --color-info-bg-strong-pressed: var(--color-palette-blue-700);
  --color-info-bg-strong-hover: var(--color-palette-blue-600);
  --color-info-bg-strong: var(--color-palette-blue-500);
  --color-info-bg-subtle: var(--color-palette-blue-800);
  --color-info-border-subtle: var(--color-palette-blue-700);
  --color-info-border: var(--color-palette-blue-400);
  --color-info-graphic: var(--color-palette-blue-400);
  --color-info-text-onbg-strong: var(--color-palette-grey-0);
  --color-info-text: var(--color-palette-blue-300);
  --color-focus-outline: var(--color-palette-blue-400);
  --color-error-bg-strong-pressed: var(--color-palette-red-700);
  --color-error-bg-strong-hover: var(--color-palette-red-600);
  --color-error-bg-strong: var(--color-palette-red-500);
  --color-error-bg-subtle: var(--color-palette-red-800);
  --color-error-border-subtle: var(--color-palette-red-700);
  --color-error-border: var(--color-palette-red-400);
  --color-error-graphic: var(--color-palette-red-400);
  --color-error-text-onbg-strong: var(--color-palette-grey-0);
  --color-error-text-hover: var(--color-palette-red-200);
  --color-error-text: var(--color-palette-red-300);
  --color-employee-bg-strong-pressed: var(--color-palette-pink-700);
  --color-employee-bg-strong-hover: var(--color-palette-pink-600);
  --color-employee-bg-strong: var(--color-palette-pink-500);
  --color-employee-bg-subtle: var(--color-palette-pink-800);
  --color-employee-bg-subtlest: var(--color-palette-pink-900);
  --color-employee-border-subtle: var(--color-palette-pink-700);
  --color-employee-border: var(--color-palette-pink-400);
  --color-employee-graphic: var(--color-palette-pink-400);
  --color-employee-text-onbg-strong: var(--color-palette-grey-0);
  --color-employee-text-hover: var(--color-palette-pink-200);
  --color-employee-text: var(--color-palette-pink-300);
  --color-disabled-bg: var(--color-palette-white-100);
  --color-disabled-border: var(--color-palette-white-100);
  --color-disabled-text: var(--color-palette-white-300);
  --color-backdrop: var(--color-palette-black-800);
  --color-attention-bg-strong-pressed: var(--color-palette-orange-700);
  --color-attention-bg-strong-hover: var(--color-palette-orange-600);
  --color-attention-bg-strong: var(--color-palette-orange-500);
  --color-attention-bg-subtle: var(--color-palette-orange-800);
  --color-attention-border-subtle: var(--color-palette-orange-700);
  --color-attention-border: var(--color-palette-orange-400);
  --color-attention-graphic: var(--color-palette-orange-400);
  --color-attention-text-onbg-strong: var(--color-palette-grey-0);
  --color-attention-text: var(--color-palette-orange-300);
}
