/* Latin */

@font-face {
  font-family: 'Roboto';
  src:
    url('@telia-front/static-assets/fonts/roboto/roboto-latin-400-normal.woff2') format('woff2'),
    url('@telia-front/static-assets/fonts/roboto/roboto-latin-400-normal.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('@telia-front/static-assets/fonts/roboto/roboto-latin-400-italic.woff2') format('woff2'),
    url('@telia-front/static-assets/fonts/roboto/roboto-latin-400-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('@telia-front/static-assets/fonts/roboto/roboto-latin-700-normal.woff2') format('woff2'),
    url('@telia-front/static-assets/fonts/roboto/roboto-latin-700-normal.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('@telia-front/static-assets/fonts/roboto/roboto-latin-700-italic.woff2') format('woff2'),
    url('@telia-front/static-assets/fonts/roboto/roboto-latin-700-italic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

/* Cyrillic */

@font-face {
  font-family: 'Roboto';
  src:
    url('@telia-front/static-assets/fonts/roboto/roboto-cyrillic-400-normal.woff2') format('woff2'),
    url('@telia-front/static-assets/fonts/roboto/roboto-cyrillic-400-normal.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  unicode-range: U+0400-04FF;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('@telia-front/static-assets/fonts/roboto/roboto-cyrillic-400-italic.woff2') format('woff2'),
    url('@telia-front/static-assets/fonts/roboto/roboto-cyrillic-400-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  unicode-range: U+0400-04FF;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('@telia-front/static-assets/fonts/roboto/roboto-cyrillic-700-normal.woff2') format('woff2'),
    url('@telia-front/static-assets/fonts/roboto/roboto-cyrillic-700-normal.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  unicode-range: U+0400-04FF;
}

@font-face {
  font-family: 'Roboto';
  src:
    url('@telia-front/static-assets/fonts/roboto/roboto-cyrillic-700-italic.woff2') format('woff2'),
    url('@telia-front/static-assets/fonts/roboto/roboto-cyrillic-700-italic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  unicode-range: U+0400-04FF;
}
